import { useEffect } from 'react'
import MainRoadmapView from './MainRoadmapView'
import PublicBoardWrapper from './PublicBoardWrapper'
import { useAtom } from 'jotai'
import { fullRoadmapAtom } from '@/atoms/orgAtom'
import { useRouter } from 'next/router'
import { activeRoadmapAtom } from '@/atoms/submissionAtom'
import { ISubmissionFilters } from '@/interfaces/ISubmission'
import { authenitcateModalAtom } from '@/atoms/authAtom'
import { useCurrentOrganization } from '@/data/organization'
import { useTranslation } from 'next-i18next'
import { useUser } from '@/data/user'

interface RoadmapPageProps {
  initialActiveExtraBaseFilters: ISubmissionFilters
}

const RoadmapPage: React.FC<RoadmapPageProps> = ({ initialActiveExtraBaseFilters }) => {
  const [isFullRoadmap, setIsFullRoadmap] = useAtom(fullRoadmapAtom)
  const [activeRoadmap, setActiveRoadmap] = useAtom(activeRoadmapAtom)
  const router = useRouter()
  const [authenitcateModal, setAuthenitacteModal] = useAtom(authenitcateModalAtom)

  const { user } = useUser()
  const { t } = useTranslation()
  const { org } = useCurrentOrganization()

  useEffect(() => {
    if (router.query?.fullScreenRoadmap === 'true' && !isFullRoadmap) {
      setIsFullRoadmap(true)
    } else if (router.query?.fullScreenRoadmap === 'false' && isFullRoadmap) {
      setIsFullRoadmap(false)
    }
  }, [router?.query, isFullRoadmap, setIsFullRoadmap])

  return (
    <PublicBoardWrapper wide={true}>
      {!activeRoadmap ? (
        <div className="flex flex-col items-center mt-28 justify-center h-full">
          <p className="text-lg font-medium dark:text-white text-gray-500">
            You do not have access to any roadmaps
          </p>
          <p className="mt-3 text-sm max-w-sm mx-auto text-center">
            {!user
              ? 'Please try logging in to gain access.'
              : `Please try a different account or contact the ${org?.displayName} team to gain access.`}
          </p>
          {!user && (
            <button
              onClick={() => setAuthenitacteModal(true)}
              className="h-9 mt-4 text-xs sm:text-[13px] px-2 sm:px-3 dashboard-secondary"
            >
              {org?.ssoUrl
                ? t('log-in-with-organization-account', {
                    organization: org.displayName,
                  })
                : `${t('sign-in')} / ${t('sign-up')}`}
            </button>
          )}
        </div>
      ) : (
        <MainRoadmapView
          enableRedirection={true}
          initialActiveExtraBaseFilters={initialActiveExtraBaseFilters}
        />
      )}
    </PublicBoardWrapper>
  )
}

export default RoadmapPage

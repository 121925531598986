import { GetServerSideProps } from 'next'

import { SWRConfig } from 'swr'
import RoadmapPage from '../../components/RoadmapPage'
import { getHTTPProtocol, redirectToRefresh } from '../../lib/subdomain'
import SeoMetaPublic from '../../components/SeoMetaPublic'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { getClientIp } from '../../lib/getClientIp'
import {
  defaultSWRConfig,
  determineSSRTheme,
  generateDefaultBaseFilterForRoadmap,
  generateDefaultFilterForRoadmapColumn,
  generateRoadmapTemplateItems,
  objectToMongooseQueryString,
} from '@/lib/utils'
import { unstable_serialize } from 'swr/infinite'
import { submissionsGetKey } from '@/data/submission'
import { IOrganization, IRoadmapDocument } from '@/interfaces/IOrganization'
import { useHydrateAtoms } from 'jotai/utils'
import { activeRoadmapAtom, submissionActiveFilterURIAtom } from '@/atoms/submissionAtom'
import { mongooseQueryStringToObject } from '@/lib/utils'
import { updateFilters } from '@/components/FilterSyncer'
import { ISubmissionFilters } from '@/interfaces/ISubmission'
import { axiosGetRequest, identifySSOUser } from '@/pages'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const host = context.req.headers['x-forwarded-host'] || context.req.headers['host']
  const cookie = context.req.headers.cookie

  // Get slug from context
  const slug = context.params?.slug?.[0]

  const ip = getClientIp(context)
  const http = getHTTPProtocol()
  const jwt = context.query?.jwt

  const [org, user, identifiedUser] = await Promise.allSettled([
    axiosGetRequest(`${http}${host}/api/v1/organization`, cookie, ip, jwt),
    axiosGetRequest(`${http}${host}/api/v1/user`, cookie, ip, jwt),
    identifySSOUser(http, host, cookie, ip, jwt),
  ])

  const fallback = {}
  let initialFilterUri = null
  let activeExtraBaseFilters: any = null
  let defaultRoadmap: IRoadmapDocument | null = null

  if (user.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/user': user.value.data })
  }

  if (identifiedUser?.status === 'fulfilled') {
    if (identifiedUser?.value?.data?.user) {
      Object.assign(fallback, { '/v1/user': identifiedUser.value.data.user })
    }
  }

  let translate = {}
  if (context.locale) {
    translate = await serverSideTranslations(context.locale, ['common'])
  }

  if (org.status === 'fulfilled') {
    Object.assign(fallback, { '/v1/organization': org.value.data })
    const orgData = org.value.data

    // Parse filters from URL
    const filters = mongooseQueryStringToObject(
      context?.req?.url ? decodeURIComponent(context?.req?.url?.split('?')[1]) : ''
    )

    const activeRoadmap = slug
      ? orgData?.roadmaps?.find((roadmap: IRoadmapDocument) => roadmap.slug === slug)
      : orgData?.roadmaps?.[0]

    const initialBaseFilters = generateDefaultBaseFilterForRoadmap(
      activeRoadmap?.baseFilter || 'upvotes:desc'
    )

    activeExtraBaseFilters = {
      sortBy: initialBaseFilters.sortBy,
      advancedFilters: filters,
      inReview: undefined,
      includePinned: true,
      limit: 10,
    } as ISubmissionFilters

    const combinedBaseFilters = updateFilters(initialBaseFilters, filters, initialBaseFilters)

    const firstRoadmapEntry = activeRoadmap

    const generatedItems =
      firstRoadmapEntry?.template?.type === 'date'
        ? generateRoadmapTemplateItems(firstRoadmapEntry, 0)
        : firstRoadmapEntry?.items

    defaultRoadmap = {
      ...firstRoadmapEntry,
      items: generatedItems,
    }

    if (!defaultRoadmap) {
      return redirectToRefresh(context.req, '/')
    }

    // Prepare requests with their respective queryStrings
    const requests = defaultRoadmap?.items?.map((item: IRoadmapDocument['items'][0]) => {
      const mainFilters = mongooseQueryStringToObject(item?.filter || '')

      const defaultFiltersForColumn = updateFilters(
        initialBaseFilters,
        mainFilters,
        initialBaseFilters
      )

      const filters = generateDefaultFilterForRoadmapColumn(
        defaultFiltersForColumn,
        activeExtraBaseFilters
      )

      // Combine default filters with URL filters
      const queryString = objectToMongooseQueryString(
        filters.advancedFilters,
        {
          sortBy: combinedBaseFilters.sortBy,
          inReview: combinedBaseFilters.inReview,
          includePinned: combinedBaseFilters.includePinned,
          q: combinedBaseFilters.q,
        },
        combinedBaseFilters,
        orgData,
        false
      ).backendQuery

      return {
        itemId: item._id,
        queryString,
        promise: axiosGetRequest(
          `${http}${host}/api/v1/submission/?page=1&` + queryString,
          cookie,
          ip,
          jwt
        ),
      }
    })

    if (!requests?.length) {
      return {
        props: {
          fallback,
          org: org.value.data,
          defaultTheme: determineSSRTheme(org?.value?.data, context),
          defaultRoadmap: null,
          queryParams: context.query,
          backendQueryString: initialFilterUri,
          initialActiveExtraBaseFilters: JSON.stringify(activeExtraBaseFilters),
          ...translate,
        },
      }
    }

    // Execute all requests
    const responses = await Promise.allSettled(requests?.map((req: any) => req?.promise))

    // Process each response
    responses.forEach((res, index) => {
      const { queryString } = requests[index]
      if (res.status === 'fulfilled') {
        Object.assign(fallback, {
          [unstable_serialize((pageIndex: number, previousPageData: any) => {
            return submissionsGetKey(pageIndex, previousPageData, queryString)
          })]: [res?.value?.data],
        })
      }
    })
  } else {
    return redirectToRefresh(context.req, '/roadmap')
  }

  return {
    props: {
      fallback,
      org: org.value.data,
      defaultTheme: determineSSRTheme(org?.value?.data, context),
      defaultRoadmap: defaultRoadmap || null,
      queryParams: context.query,
      backendQueryString: initialFilterUri,
      initialActiveExtraBaseFilters: JSON.stringify(activeExtraBaseFilters),
      ...translate,
    },
  }
}

const Roadmap: React.FC<{
  fallback: any
  org: IOrganization
  backendQueryString: string
  initialActiveExtraBaseFilters: string
  defaultRoadmap: IRoadmapDocument | null
}> = ({ fallback, org, backendQueryString, initialActiveExtraBaseFilters, defaultRoadmap }) => {
  const { t } = useTranslation()
  useHydrateAtoms([[submissionActiveFilterURIAtom, backendQueryString]])
  useHydrateAtoms([[activeRoadmapAtom, defaultRoadmap]])

  return (
    <SWRConfig value={{ fallback, ...defaultSWRConfig }}>
      <SeoMetaPublic
        page={t('roadmap')}
        ogImageProps={
          (org &&
            !org.settings.private && {
              company: {
                name: org?.displayName,
                logo: org?.picture,
                themeColor: org?.color,
                themeLinePosition: 'bottom',
              },
              title: t('roadmap'),
              description: "See what's coming next and vote for your favorite ideas.",
              // category: t('roadmap'),
              type: 'root',
            }) ||
          undefined
        }
      />
      <style>{`
       :root {
        --background: #fff;
      }
      `}</style>
      <RoadmapPage
        initialActiveExtraBaseFilters={
          JSON.parse(initialActiveExtraBaseFilters || '{}') as ISubmissionFilters
        }
      />
    </SWRConfig>
  )
}

export default Roadmap
